import { isAndroid, isIOS, isSafari } from 'react-device-detect';
import { ELoginMethods } from '../../constants/enums';
import { EventSharedProps } from 'constants/apiRequest.types';

const isWebviewBrowser = (): boolean => {
  const userAgent = navigator.userAgent.toLowerCase();
  return (
    userAgent.indexOf('instagram') > -1 ||
    userAgent.indexOf('fban') > -1 ||
    userAgent.indexOf('fbav') > -1 ||
    userAgent.indexOf('fb_iab') > -1 ||
    userAgent.indexOf('messenger') > -1
  );
};

const checkIfOlderOsVersions = (): boolean => {
  const ua = navigator?.userAgent?.toLowerCase();
  if (!ua) return true;

  const isChromeIOS = /crios/.test(ua);

  let versionMatch = ua.match(/version\/(\d+(\.\d+)?)/);

  if (!versionMatch) {
    versionMatch = ua.match(/crios\/(\d+(\.\d+)?)/); // Find version for Chrome
  }

  if (!versionMatch) return false; // Unable to extract version, consider it older

  const osMobileVersion = parseFloat(versionMatch[1]);
  if (isNaN(osMobileVersion)) return true; // Parsing failed, consider it older

  const SAFARI_IOS_THRESHOLD = 14.5;
  const CHROME_IOS_THRESHOLD = 92.1;
  const ANDROID_THRESHOLD = 123;

  return (
    ((isSafari || isIOS) && osMobileVersion < SAFARI_IOS_THRESHOLD) ||
    (isAndroid && osMobileVersion < ANDROID_THRESHOLD) ||
    (isChromeIOS && osMobileVersion < CHROME_IOS_THRESHOLD)
  );
};

const getLoginEventsData = ({
  eventSharedProps,
  method,
  options,
}: {
  eventSharedProps: EventSharedProps,
  method?: ELoginMethods,
  options?: Record<string, string>
}) => {
  return {
    timestamp: eventSharedProps.timestamp,
    sessionId: eventSharedProps.sessionId,
    device_manufacture: eventSharedProps.device_manufacturer,
    device_model: eventSharedProps.device_model,
    os: eventSharedProps.os,
    os_version: eventSharedProps.os_version,
    browser: eventSharedProps.browser,
    browser_version: eventSharedProps.browser_version,
    platform: eventSharedProps.platform,
    method,
    source: sessionStorage.getItem('source'),
    ...options
  };
};

export const loginPageService = {
  isWebviewBrowser,
  checkIfOlderOsVersions,
  getLoginEventsData,
};
