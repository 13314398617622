import useApi from '../../../hooks/useApi';
import { BootResponse } from '../../../constants/apiResponses.types';
import { Banner } from '@appcharge/shared-ui';
import React, { useMemo } from 'react';
import { EBundlesInternalViewModel, EQueryParams } from '../../../constants/enums';
import { useOffers } from '../hooks/useOffers.hook';
import useUrlQuery from 'hooks/useUrlQuery';

enum EConfigs {
  bannerMaxWidth = 'bannerMaxWidth',
  bannerMinWidth = 'bannerMinWidth',
  bannerMX = 'bannerMarginX',
}

const mapBundlesConfigs: Record<EConfigs, Partial<Record<EBundlesInternalViewModel, any>>> = {
  [EConfigs.bannerMaxWidth]: {
    [EBundlesInternalViewModel.WHISPERS]: '418px',
    [EBundlesInternalViewModel.PIPA]: '528px',
    [EBundlesInternalViewModel.PLAY_SIMPLE]: '322px',
  },
  [EConfigs.bannerMinWidth]: {
    [EBundlesInternalViewModel.WHISPERS]: '343px',
    [EBundlesInternalViewModel.PLAY_SIMPLE]: '224px',
  },
  [EConfigs.bannerMX]: {
    [EBundlesInternalViewModel.PLAY_SIMPLE]: '25%',
  },
};

const defaultConfigs: Record<EConfigs, any> = {
  [EConfigs.bannerMaxWidth]: undefined,
  [EConfigs.bannerMinWidth]: undefined,
  [EConfigs.bannerMX]: undefined,
};

const getProperty: any = (property: EConfigs, viewModel: EBundlesInternalViewModel) =>
  mapBundlesConfigs[property][viewModel] || defaultConfigs[property];

export const BannerWrapper: React.FC = () => {
  const campaignOfferId = useUrlQuery(EQueryParams.OFFER_ID);
  const { hasNoOffers } = useOffers();
  const API = useApi({});
  const publisherMetaData = API.getPublisherMeta.data as BootResponse;
  const { bundlesInternalViewModel } = publisherMetaData.storeTheme.general;

  const bannerImage = useMemo(() => {
    if (hasNoOffers || !API.getOffers?.data || campaignOfferId) {
      return '';
    }

    const bannerKeyValue =
      API.getOffers.data.bannerImage || publisherMetaData.storeTheme?.storeScreen?.defaultBanner;

    if (bannerKeyValue) return bannerKeyValue;

    if (
      API.getOffers.data.playerLevelImg &&
      [
        EBundlesInternalViewModel.CASHMAN_CASINO,
        EBundlesInternalViewModel.PIPA,
        EBundlesInternalViewModel.LIGHTNING_LINK_CASINO,
        EBundlesInternalViewModel.MIGHTY_FU_CASINO,
      ].includes(bundlesInternalViewModel)
    ) {
      return API.getOffers.data.playerLevelImg;
    }

    return '';
  }, [API.getOffers?.data]);

  if (!bannerImage) return <></>;

  return (
    <Banner
      imageSrc={bannerImage}
      maxWidth={getProperty(EConfigs.bannerMaxWidth, bundlesInternalViewModel)}
      minWidth={getProperty(EConfigs.bannerMinWidth, bundlesInternalViewModel)}
      mx={getProperty(EConfigs.bannerMX, bundlesInternalViewModel)}
    />
  );
};
