import { useEffect, useState } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { AddToHomePageModal, AddToHomeScreenButton } from '@appcharge/shared-ui';
import useSaveToHomePage from 'hooks/useSaveToHomePage';
import useStoreAppearance from 'hooks/useStoreAppearance';
import { EEventsType, EPlatformType, EStorePhase } from 'constants/enums';
import { BootResponse } from 'constants/apiResponses.types';
import { UseCustomEventsReturn } from 'hooks/useCustomEvents';

const AddToHomePage = ({
  publisherMetaData,
  customEvents,
}: {
  publisherMetaData: BootResponse;
  customEvents: UseCustomEventsReturn;
}) => {
  const [devicePlatform, setDevicePlatform] = useState(EPlatformType.WEB);
  const {
    isSaveToHomePageOpen,
    setIsSaveToHomePageOpen,
    handleCloseSaveToHomePage,
    isDisplayAddToHomePageButton,
    handleInstallApp,
    isAndroidFallBack,
  } = useSaveToHomePage(publisherMetaData);
  const isDarkMode = useStoreAppearance();
  const { t } = useTranslation();

  useEffect(() => {
    if (isIOS) {
      setDevicePlatform(EPlatformType.IOS);
    }
    if (isAndroid) {
      setDevicePlatform(EPlatformType.ANDROID);
    }
  }, [isAndroid, isIOS]);

  if (!isDisplayAddToHomePageButton) {
    return null;
  }

  const isIOSBanner = isIOS && isSaveToHomePageOpen;
  const isAndroidBanner = isAndroid && isAndroidFallBack;

  const handleAddToHomeScreen = () => {
    if (isIOS) {
      setIsSaveToHomePageOpen(true);
    }
    if (isAndroid) {
      return handleInstallApp();
    }

    customEvents.sendCustomEvent(
      EEventsType.HOME_SCREEN_BUTTON_CLICK,
      {
        platform: devicePlatform,
      },
      EStorePhase.POST_LOGIN
    );
  };

  return (
    <>
      <AddToHomeScreenButton
        onClick={handleAddToHomeScreen}
        borderColor={publisherMetaData.storeTheme.storeScreen.addToHomeScreen?.borderColor.colorOne}
        backgroundColor={publisherMetaData.storeTheme.storeScreen.addToHomeScreen?.backgroundColor}
        translations={{ addToHomeScreen: t('shop.addToHomeScreen') }}
      />
      {(isIOSBanner || isAndroidBanner) && (
        <AddToHomePageModal
          publisherStoreLogo={publisherMetaData.storeTheme.general.logo}
          publisherStoreName={publisherMetaData.publisher?.storeTabName}
          isDarkMode={isDarkMode}
          onClick={handleCloseSaveToHomePage}
          isAndroid={isAndroidBanner}
          translations={{
            installInstruction: t('addToHomeModal.installInstruction', {
              companyName: publisherMetaData.publisher?.storeTabName,
            }),
            description: t('addToHomeModal.description'),
            moreInfoStep: t('addToHomeModal.moreInfoStep'),
            shareStep: t('addToHomeModal.shareStep'),
            addStep: t('addToHomeModal.addStep'),
          }}
        />
      )}
    </>
  );
};

export default AddToHomePage;
