import React, { useEffect, useState } from 'react';
import { OfferData } from 'constants/apiResponses.types';
import '../style.scss';
import { EQueryParams } from 'constants/enums';
import useApi from 'hooks/useApi';
import useUrlQuery from 'hooks/useUrlQuery';
import { RollingOffer } from 'components/RollingOffer/RollingOffer';
import { Box } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';

interface IRollingOffersWrapperProps {
  selectOffer: (offer: OfferData) => void;
}

export const RollingOffersWrapper: React.FC<IRollingOffersWrapperProps> = ({ selectOffer }) => {
  const campaignOfferId = useUrlQuery(EQueryParams.OFFER_ID);
  const [rollingOffersList, setRollingOffersList] = useState<OfferData[]>([]);

  const API = useApi({});

  useEffect(() => {
    if (!API.getOffers.data?.offers?.rolling || campaignOfferId) return;

    setRollingOffersList(API.getOffers.data.offers.rolling);
  }, [API.getOffers.data?.offers?.rolling]);

  return (
    <Box className={'rolling-offers-wrapper'}>
      <AnimatePresence>
        {rollingOffersList.map((rollingOffer: OfferData) => (
          <motion.div
            key={rollingOffer.offerId}
            initial={{ opacity: 1, scale: 1 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0 }}
            transition={{ duration: 0.5 }}
          >
            <RollingOffer
              key={rollingOffer.offerId}
              data={rollingOffer}
              selectOffer={() => selectOffer(rollingOffer)}
            />
          </motion.div>
        ))}
      </AnimatePresence>
    </Box>
  );
};
