import React, { useEffect, useRef } from 'react';
import useApi from 'hooks/useApi';
import { BootResponse, OfferData } from 'constants/apiResponses.types';
import { HugeRollingOffer, OrderRollingOfferEvents, RollingOfferProps } from '@appcharge/shared-ui';
import { ECheckoutPageEvent, ERollingOffersInternalViewModel } from 'constants/enums';

import { useAppState } from 'state/hooks/app.state.hook';

const templateDesignDic: Record<ERollingOffersInternalViewModel, React.FC<RollingOfferProps>> = {
  [ERollingOffersInternalViewModel.HUGE]: HugeRollingOffer,
};

interface ISpecialOfferProps {
  selectOffer: () => void;
  data: OfferData;
}

export const RollingOffer: React.FC<ISpecialOfferProps> = ({ data, selectOffer }) => {
  const API = useApi({});
  const publisherMetaData = API.getPublisherMeta.data as BootResponse;
  const rollingOfferRef = useRef<OrderRollingOfferEvents>(null);
  const { rollingOffersInternalViewModel } = publisherMetaData.storeTheme.general;
  const { setShowConfetti } = useAppState();

  const RollingOfferDesign: React.FC<RollingOfferProps> =
    templateDesignDic[rollingOffersInternalViewModel || ERollingOffersInternalViewModel.HUGE];

  useEffect(() => {
    let animationTimeout: NodeJS.Timeout;
    const eventHandler = (massageEvent: any) => {
      if (massageEvent.origin !== window.location.origin) return;
      const { event, params } = massageEvent.data;

      if (event === ECheckoutPageEvent.BACK_TO_STORE) {
        const offer = params?.offer;
        const isCurrentOffer = offer?.offerId === data.offerId;

        if (isCurrentOffer) {
          if (params.isOrderSucceed) {
            if (offer.price === 0) {
              setShowConfetti(true);
            }
            rollingOfferRef?.current?.onOrderSuccess();
            API.getOffers.refetch();
          } else {
            rollingOfferRef?.current?.onOrderFail();
            API.getOffers.refetch();
          }
        }
      }
    };

    window.addEventListener('message', eventHandler);

    return () => {
      window.removeEventListener('message', eventHandler);
      if (animationTimeout) clearTimeout(animationTimeout);
    };
  }, []);

  return (
    <RollingOfferDesign
      ref={rollingOfferRef}
      offerId={data.offerId}
      indexToCollect={data.indexToCollect}
      collectBehavior={data.offerUi.rollingOffer!.collectBehavior}
      backgroundColor={data.offerUi.backgroundColor}
      backgroundImage={data.offerUi.backgroundImage}
      borderColor={data.offerUi.borderColor}
      borderWidth={data.offerUi.borderWidth}
      arrowColor={data.offerUi.rollingOffer!.arrowColor}
      template={rollingOffersInternalViewModel || ERollingOffersInternalViewModel.HUGE}
      subRollingOffersProducts={data.productsSequence}
      subRollingOfferUiProps={{
        ...data.offerUi.rollingOffer!.subRollingOffer,
        buttonColor: publisherMetaData.storeTheme.general.buttonColor,
        buttonTextColor: publisherMetaData.storeTheme.general.buttonTextColor,
      }}
      selectOffer={selectOffer}
      playerAvailability={data.offerPlayerRemainingAvailability}
      availability={data.playerAvailability}
    />
  );
};
