import { useState, useEffect } from 'react';
import useApi from 'hooks/useApi';
import { ELocalStorageKeys } from 'constants/enums';
import { localStorageUtil } from 'state/localStorage.state.service';

export const useOffers = () => {
  const API = useApi({});
  const [hasNoOffers, setHasNoOffers] = useState<boolean>(false);

  useEffect(() => {
    setHasNoOffers(
      API.getOffers.data &&
        localStorageUtil.get(ELocalStorageKeys.SESSION_TOKEN) &&
        API.getOffers.data.offers?.bundles?.length === 0 &&
        API.getOffers.data.offers?.promotions?.length === 0
    );
  }, [API.getOffers.data]);

  return {
    hasNoOffers,
  };
};
