import { useObservable } from '../observable';
import { appStateService } from '../app.state.service';

export const useAppState = () => ({
  displayLoader: useObservable(appStateService.displayLoader),
  showConfetti: useObservable(appStateService.showConfetti),
  selectedOffer: useObservable(appStateService.selectedOffer),
  setDisplayLoader: appStateService.setDisplayLoader,
  setShowConfetti: appStateService.setShowConfetti,
  setSelectedOffer: appStateService.setSelectedOffer,
});
